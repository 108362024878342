import { useRouter } from "vue-router";

const useMenuManager = () => {
  const router = useRouter();

  function scrollTop() {
    window.scrollTo(0, 0);
  }

  function doBeforeMove() {
    scrollTop();
  }

  function goHome() {
    doBeforeMove();
    router.push({
      name: "home",
      params: {},
    });
  }

  function goLogin(d: any) {
    doBeforeMove();
    router.push({
      name: "Login",
      params: {},
    });
  }

  function goSocialLoginFailure() {
    doBeforeMove();
    router.push({
      name: "SocialLoginFailure",
      params: {},
    });
  }

  function goJoin() {
    doBeforeMove();
    router.push({
      name: "Join",
      params: {},
    });
  }

  function goSearch(query: any) {
    doBeforeMove();
    router.push({
      name: "Search",
      query,
      params: {},
    });
  }

  function goMyInteractiveVideoList() {
    doBeforeMove();
    router.push({
      name: "MyInteractiveVideoList",
      params: {},
    });
  }

  function goMyFavoritesList() {
    doBeforeMove();
    router.push({
      name: "MyFavoriatesList",
      params: {},
    });
  }

  function goVideoDetail(publishingCode: string) {
    doBeforeMove();
    router.push({
      name: "VideoDetail",
      params: { publishingCode },
    });
  }

  function goCreateVideo() {
    doBeforeMove();
    router.push({
      name: "VideoCreate",
      params: {},
    });
  }

  function goModifyVideo(id: string) {
    doBeforeMove();
    router.push({
      name: "VideoModify",
      params: {
        id,
      },
    });
  }

  return {
    goHome,
    goLogin,
    goSocialLoginFailure,
    goJoin,
    goSearch,
    goMyInteractiveVideoList,
    goMyFavoritesList,
    goVideoDetail,
    goCreateVideo,
    goModifyVideo,
  };
};

export { useMenuManager };
