import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/join",
    name: "Join",
    component: () => import("../views/JoinView.vue"),
  },
  {
    path: "/social/login/failure",
    name: "SocialLoginFailure",
    component: () => import("../views/SocialLoginFailureView.vue"),
  },
  {
    path: "/social/login/success",
    name: "SocialLoginSuccess",
    component: () => import("../views/SocialLoginSuccessView.vue"),
  },
  {
    path: "/my/videolist",
    name: "MyInteractiveVideoList",
    component: () => import("../views/UserInteractiveVideoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my/favoriates",
    name: "MyFavoriatesList",
    component: () => import("../views/UserFavoritesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/v/:publishingCode",
    name: "VideoDetail",
    component: () => import("../views/VideoDetailView.vue"),
  },
  {
    path: "/video/create",
    name: "VideoCreate",
    component: () => import("../views/VideoCreateView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/video/modify/:id",
    name: "VideoModify",
    component: () => import("../views/VideoModifyView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/SearchView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters[`user/isLogin`]
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
