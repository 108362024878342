
import MainLayout from "./layout/MainLayout.vue";

export default {
  name: "App",
  components: {
    MainLayout,
  },
  setup() {
    return {};
  },
};
