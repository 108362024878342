
import MainHeader from "@/components/MainHeader.vue";
import MainLeftMenu from "@/components/MainLeftMenu.vue";

export default {
  name: "MainLayout",

  components: {
    MainHeader,
    MainLeftMenu,
  },

  setup() {
    return {
      // leftDrawerOpen: ref(false),
    };
  },
};
