import axios from "axios";
import { useStore } from "vuex";
import { ref } from "vue";
import { UserMutationTypes } from "@/store/mutationTypes";
import { UserActionTypes } from "@/store/actionTypes";
import jwt from "@/common/jwt";
import { waitUntil } from "@/common/times";
import memberApis from "./modules/memberApis";
import interactiveVideoApis from "./modules/interactiveVideoApis";

const AVOID_REQUEST_INTERCEPTOR_URLS = [
  "/member/login",
  "/member/reissue/token",
];

// 반드시 전역으로 선언해야 한다.
const REQUEST_CRITICAL_SECTION = { value: 0 };

const defaultBaseUrl = "https://uapis.overflowing.info/v1/apis";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || defaultBaseUrl,
  timeout: 10000, // 10초
});

const useApis = () => {
  const store = useStore();

  const enterCliticalSection = () => {
    REQUEST_CRITICAL_SECTION.value += 1;
  };

  const escapeCliticalSection = () => {
    REQUEST_CRITICAL_SECTION.value -= 1;
    REQUEST_CRITICAL_SECTION.value = Math.max(
      REQUEST_CRITICAL_SECTION.value,
      0
    );
  };

  const isInCriticalSection = () => {
    return REQUEST_CRITICAL_SECTION.value > 0;
  };

  axiosInstance.interceptors.request.use(async (config) => {
    const url = config.url ? config.url : "";

    if (AVOID_REQUEST_INTERCEPTOR_URLS.includes(url)) {
      return config;
    }

    if (REQUEST_CRITICAL_SECTION.value) {
      await waitUntil(() => {
        console.log(
          "... waitUntil",
          config.url,
          REQUEST_CRITICAL_SECTION.value
        );
        return !isInCriticalSection();
      }, 100);
    }

    enterCliticalSection();

    const accessToken = store.getters["user/getAccessToken"];
    const refreshToken = store.getters["user/getRefreshToken"];

    if (!accessToken && !refreshToken) {
      // access-token과 refresh-token이 모두 없을 경우에는 헤더에 토큰을 추가하지 않는다.
      escapeCliticalSection();
      return config;
    }

    if (!jwt.isExpired(accessToken)) {
      // access-token이 존재하고 만료되지 않았을 경우에는 헤더에 토큰을 추가한다.
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      escapeCliticalSection();
      return config;
    }

    if (jwt.isExpired(refreshToken)) {
      // refresh-token이 없거나 만료되었을 경우에는 세션아웃 처리
      sessionOut();
      escapeCliticalSection();
      return config;
    }

    // refresh-token을 이용하여 토큰을 갱신한다.
    const { data } = await reissueToken(refreshToken);

    if (!data.result) {
      sessionOut();
    } else {
      store.dispatch(`user/${UserActionTypes.SET_LOGIN_INFO}`, data.data);
    }

    escapeCliticalSection();

    return config;
  });

  axiosInstance.interceptors.response.use((response) => {
    // access-token
    if (response.headers["authorization"]) {
      store.commit(
        `user/${UserMutationTypes.SET_ACCESS_TOKEN}`,
        response.headers["authorization"]
      );
    }

    // refresh-token
    if (response.headers["authorization-refresh"]) {
      store.commit(
        `user/${UserMutationTypes.SET_REFRESH_TOKEN}`,
        response.headers["authorization-refresh"]
      );
    }

    return response;
  });

  function sessionOut() {
    store.dispatch(`user/${UserActionTypes.CLEAR_ALL_STATE}`);
  }

  async function reissueToken(refreshToken: string) {
    return await axiosInstance.post("/member/reissue/token", { refreshToken });
  }

  return {
    member: memberApis,
    interactiveVideo: interactiveVideoApis,
  };
};

export { useApis, axiosInstance };
