import { UserActionTypes } from "@/store/actionTypes";
import { useApis } from "@/apis";
import { useStore } from "vuex";
import { useDialog } from "@/composibles/dialog";
import { useRouter } from "vue-router";
import { useMenuManager } from "@/composibles/menuManager";

const useUserManager = () => {
  const apis = useApis();
  const store = useStore();
  const dialog = useDialog();
  const router = useRouter();
  const menuManager = useMenuManager();

  const stateName = "user";

  getSessionAlive();

  const login = async (userid: string, passwd: string) => {
    const { data } = await apis.member.login(userid, passwd);
    if (data.result) {
      store.dispatch(
        `${stateName}/${UserActionTypes.SET_LOGIN_INFO}`,
        data.data
      );
    }
    return data;
  };

  const logout = () => {
    store.dispatch(`${stateName}/${UserActionTypes.CLEAR_ALL_STATE}`);
  };

  function getSessionAlive() {
    return apis.member.getSessionAlive();
  }

  const isLogin = () => {
    return store.getters[`${stateName}/isLogin`];
  };

  const checkLoginAndGoBack = async () => {
    await getSessionAlive();

    const { name, params, query } = router.currentRoute.value;

    if (!isLogin()) {
      dialog.alert("로그인이 필요한 서비스입니다.");
      menuManager.goLogin({
        n: name,
        p: params,
        q: query,
      });
    }
  };

  return {
    login,
    logout,
    isLogin,
    checkLoginAndGoBack,
    getSessionAlive,
  };
};

export { useUserManager };
