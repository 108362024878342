
import useQuasar from 'quasar/src/composables/use-quasar.js';import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { computed, defineProps } from "vue";

const tempImageUrlList = [
  "https://cdn.quasar.dev/img/mountains.jpg",
  "https://cdn.quasar.dev/img/parallax2.jpg",
  "https://cdn.quasar.dev/img/chicken-salad.jpg",
  "https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2020/11/19/d7dace82586046dd955e891ddfa5713a_Seoul+Gangnam.jpg",
];

export default {
  emits: [
    // REQUIRED; need to specif  y some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits,
  ],

  props: {
    shareUrl: String,
    thumbnailUrl: String,
    uid: Number,
    title: String,
  },

  setup(props: any) {
    const $q = useQuasar();

    const imgUrl = computed(() => {
      const { thumbnailUrl } = props;
      if (!thumbnailUrl) {
        const uid = props.uid !== undefined ? props.uid : 0;
        const idx = uid % tempImageUrlList.length;
        return tempImageUrlList[idx];
      }
      return thumbnailUrl;
    });

    const url = computed(() => {
      return props.shareUrl;
    });

    const onClickBtnCopyUrl = async () => {
      await navigator.clipboard.writeText(url.value);

      $q.notify({
        message: "공유 주소가 클립보드에 복사되었습니다.",
        icon: "share",
        color: "info",
        position: "center",
        timeout: 2000,
      });
    };

    const onClickBtnOpenUrl = () => {
      window.open(props.shareUrl, "_blank");
      onDialogCancel();
    };

    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome

    return {
      imgUrl,
      url,
      onClickBtnCopyUrl,
      onClickBtnOpenUrl,
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK();
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel,
    };
  },
};

// console.log(props.shareUrl);
