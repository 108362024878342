import { axiosInstance } from "..";

export default {
  login: async (userid: string, passwd: string) => {
    return axiosInstance.post("/member/login", {
      userid,
      passwd,
    });
  },

  getSessionAlive: async () => {
    return axiosInstance.get("/member/session/alive");
  },
};
