import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row no-wrap items-center" }
const _hoisted_2 = { class: "col text-h6 ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    onHide: $setup.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "q-dialog-plugin",
        style: {"width":"440px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_img, { src: $setup.imgUrl }, null, 8, ["src"]),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1)
              ]),
              _createVNode(_component_q_input, {
                modelValue: $setup.url,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.url) = $event)),
                dense: true,
                readonly: ""
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "share" })
                ]),
                after: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    icon: "content_copy",
                    label: "COPY",
                    class: "text-caption",
                    color: "teal",
                    onClick: $setup.onClickBtnCopyUrl
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "content_copy",
                            class: "q-mr-xs"
                          }),
                          _createTextVNode(" 공유 주소를 클립보드에 복사합니다.")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                round: "",
                icon: "rocket_launch",
                color: "blue-10",
                onClick: $setup.onClickBtnOpenUrl
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode("공유주소로 새 창을 엽니다.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                flat: "",
                color: "primary",
                onClick: $setup.onCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" CLOSE ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}