import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;
import quasarUserOptions from "./quasar-user-options";

/* font-awesome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);

quasarUserOptions.plugins = { Dialog, Notify };

const Kakao = (window as any).Kakao;
// Kakao.init(process.env.VUE_APP_KAKAO_JS_KEY);
// Kakao.isInitialized();

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);
app.provide("$axios", axios);
app.mount("#app");

app.config.globalProperties.$kakao = Kakao;
