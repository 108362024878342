export const enum VideoType {
  YOUTUBE = "YOUTUBE",
  ETC_URL = "ETC_URL",
}

export interface SourceVideoInfo {
  videoType: VideoType;
  videoId?: string;
  videoUrl?: string;
}

export interface InteractiveVideo {
  id: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  interactiveVideoId: string;
  publishedYn: "Y" | "N";
  publishingCode: string;
  tagList: string[];
  tags: string;
}

export interface InteractiveVideoDetail extends InteractiveVideo {
  registedTimestamp: string;
  nickname: string;
  profileImageUrl: string;
  registedUid: number;
  base64Thumbnail: string;
}

export interface PaginationInfo {
  currentPageNo: number;
  recordCountPerPage: number;
  recordSize: number;
  pageSize: number;
}

export interface SelectOptionsType {
  label: string;
  value: string;
}
