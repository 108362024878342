import { axiosInstance } from "..";

export default {
  getMyList: async (page: number, options: any) => {
    return await axiosInstance.get(`/interactivevideo/my/list/${page}`, {
      params: options,
    });
  },

  getList: async (page: number) => {
    return await axiosInstance.get(`/interactivevideo/list/${page}`);
  },

  searchList: async (page: number, options: any) => {
    return await axiosInstance.get(`/interactivevideo/list/${page}`, {
      params: {
        ...options,
      },
    });
  },

  create: async (formData: FormData) => {
    return await axiosInstance.post("/interactivevideo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  modify: async (formData: FormData) => {
    return await axiosInstance.post("/interactivevideo/modify", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  publishing: async (params: any) => {
    return await axiosInstance.post("/interactivevideo/my/publishing", {
      ...params,
    });
  },
};
