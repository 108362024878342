import script from "./MainHeader.vue?vue&type=script&lang=ts&setup=true"
export * from "./MainHeader.vue?vue&type=script&lang=ts&setup=true"

import "./MainHeader.vue?vue&type=style&index=0&id=eb277baa&lang=sass"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QIcon,QToolbarTitle,QSpace,QInput,QTooltip,QBadge,QAvatar,QMenu,QList,QItem,QItemSection,QSeparator});qInstall(script, 'directives', {ClosePopup});
