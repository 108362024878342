import axios from "axios";
import { Module } from "vuex";
import { InteractiveVideoMutationTypes } from "../mutationTypes";
import { InteractiveVideoActionTypes } from "../actionTypes";
import { RootState } from "..";
import {
  VideoType,
  SourceVideoInfo,
  InteractiveVideo,
  InteractiveVideoDetail,
} from "@/common/types";
import { axiosInstance } from "@/apis";

export interface InteractiveVideoState {
  sourceVideoInfo: SourceVideoInfo;
  interactiveVideo: InteractiveVideoDetail;
}

export const interactiveVideoStore: Module<InteractiveVideoState, RootState> = {
  namespaced: true,
  state: () => ({
    sourceVideoInfo: {
      videoType: VideoType.YOUTUBE,
      videoId: "",
      videoUrl: "",
    },
    interactiveVideo: {
      id: "",
      publishingCode: "",
      title: "",
      description: "",
      thumbnailUrl: "",
      interactiveVideoId: "",
      publishedYn: "N",
      tagList: [],
      tags: "",
      registedTimestamp: "",
      nickname: "",
      profileImageUrl: "",
      registedUid: 0,
      base64Thumbnail: "",
    },
  }),

  getters: {
    getSourceVideoType(state) {
      return state.sourceVideoInfo.videoType;
    },
    getSourceVideoUrl(state) {
      return state.sourceVideoInfo.videoUrl;
    },
    getSourceVideoId(state) {
      return state.sourceVideoInfo.videoId;
    },
    getInteractiveVideoId(state) {
      return state.interactiveVideo.interactiveVideoId;
    },
    getInteractiveVideo(state) {
      return state.interactiveVideo;
    },
    getId(state) {
      return state.interactiveVideo.id;
    },
    getTagList(state) {
      if (state.interactiveVideo.tagList == null) {
        state.interactiveVideo.tagList = [];
      }
      return state.interactiveVideo.tagList;
    },
  },

  mutations: {
    [InteractiveVideoMutationTypes.SET_ID](state, id) {
      state.interactiveVideo.id = id;
    },

    [InteractiveVideoMutationTypes.SET_INTERACTIVE_VIDEO_ID](
      state,
      interactiveVideoId
    ) {
      state.interactiveVideo.interactiveVideoId = interactiveVideoId;
    },

    [InteractiveVideoMutationTypes.SET_TITLE](state, title) {
      state.interactiveVideo.title = title;
    },

    [InteractiveVideoMutationTypes.SET_DESCRIPTION](state, description) {
      state.interactiveVideo.description = description;
    },

    [InteractiveVideoMutationTypes.SET_TAGS](state, tags) {
      state.interactiveVideo.tags = tags;

      // set tagList
      let tagList = tags.split(",");
      tagList = tagList == null ? [] : tagList;
      state.interactiveVideo.tagList = tagList;
    },

    [InteractiveVideoMutationTypes.SET_TAG_LIST](state, tagList) {
      state.interactiveVideo.tagList = tagList;
    },

    [InteractiveVideoMutationTypes.SET_THUMBNAIL_URL](state, thumbnailUrl) {
      state.interactiveVideo.thumbnailUrl = thumbnailUrl;
    },

    [InteractiveVideoMutationTypes.SET_PUBLISHED_YN](state, publishedYn) {
      state.interactiveVideo.publishedYn = publishedYn;
    },

    [InteractiveVideoMutationTypes.SET_VIDEO_TYPE](state, videoType) {
      state.sourceVideoInfo.videoType = videoType;
    },

    [InteractiveVideoMutationTypes.SET_VIDEO_ID](state, videoId) {
      state.sourceVideoInfo.videoId = videoId;
    },

    [InteractiveVideoMutationTypes.SET_VIDEO_URL](state, videoUrl) {
      state.sourceVideoInfo.videoUrl = videoUrl;
    },

    [InteractiveVideoMutationTypes.SET_BASE64_THUMBNAIL](
      state,
      base64Thumbnail
    ) {
      state.interactiveVideo.base64Thumbnail = base64Thumbnail;
    },

    [InteractiveVideoMutationTypes.SET_INTERACTIVE_VIDEO](state, payload) {
      state.interactiveVideo.id = payload.uid;
      state.interactiveVideo.title = payload.title;
      state.interactiveVideo.description = payload.description;
      state.interactiveVideo.thumbnailUrl = payload.thumbnailUrl;
      state.interactiveVideo.interactiveVideoId = payload.interactiveVideoId;
      state.interactiveVideo.publishedYn = payload.publishedYn;
      state.interactiveVideo.publishingCode = payload.publishingCode;
      state.interactiveVideo.registedTimestamp = payload.registedTimestamp;
      state.interactiveVideo.nickname = payload.nickname;
      state.interactiveVideo.profileImageUrl = payload.profileImageUrl;
      state.interactiveVideo.registedUid = payload.registedUid;

      if (payload.tagList == null) {
        state.interactiveVideo.tagList = payload.tagList;
      }

      // base64Thumbnail은 별도 처리
    },
  },

  actions: {
    [InteractiveVideoActionTypes.SET_SOURCE_VIDEO]({ commit }, payload) {
      commit(InteractiveVideoMutationTypes.SET_VIDEO_ID, payload?.videoId);
      commit(InteractiveVideoMutationTypes.SET_VIDEO_URL, payload?.videoUrl);
    },

    [InteractiveVideoActionTypes.CLEAR_SOURCE_VIDEO]({ commit }) {
      commit(InteractiveVideoMutationTypes.SET_VIDEO_ID, "");
      commit(InteractiveVideoMutationTypes.SET_VIDEO_URL, "");
    },

    [InteractiveVideoActionTypes.CLEAR_INTERACTIVE_VIDEO]({ commit }) {
      commit(InteractiveVideoMutationTypes.SET_INTERACTIVE_VIDEO, {
        uid: "",
        title: "",
        description: "",
        thumbnailUrl: "",
        interactiveVideoId: "",
        publishedYn: "N",
        publishingCode: "",
        registedTimestamp: "",
        nickname: "",
        profileImageUrl: "",
        registedUid: 0,
      });
    },

    [InteractiveVideoActionTypes.SET_INTERACTIVE_VIDEO_INFO](
      { commit },
      payload
    ) {
      commit(InteractiveVideoMutationTypes.SET_TITLE, payload?.title);
      commit(
        InteractiveVideoMutationTypes.SET_DESCRIPTION,
        payload?.description
      );
    },

    [InteractiveVideoActionTypes.CLEAR_ALL]({ dispatch }) {
      dispatch(InteractiveVideoActionTypes.CLEAR_SOURCE_VIDEO);
      dispatch(InteractiveVideoActionTypes.CLEAR_INTERACTIVE_VIDEO);
    },

    async [InteractiveVideoActionTypes.GET_DETAIL_BY_ID](
      { commit, dispatch },
      id
    ) {
      dispatch(InteractiveVideoActionTypes.CLEAR_ALL);

      const { data } = await axiosInstance.get(`/interactivevideo/my/${id}`);
      if (!data.result || !data.data) {
        return;
      }

      commit(InteractiveVideoMutationTypes.SET_INTERACTIVE_VIDEO, data.data);

      if (data.data.tags != null && data.data.tags !== "") {
        commit(InteractiveVideoMutationTypes.SET_TAGS, data.data.tags);
      }

      // source video info
      commit(
        InteractiveVideoMutationTypes.SET_VIDEO_TYPE,
        data.data.sourceVideoType
      );

      commit(
        InteractiveVideoMutationTypes.SET_VIDEO_URL,
        data.data.sourceVideoUrl
      );

      commit(
        InteractiveVideoMutationTypes.SET_VIDEO_ID,
        data.data.sourceVideoId
      );
    },

    async [InteractiveVideoActionTypes.GET_DETAIL_BY_PUBLSHING_CODE](
      { commit, dispatch },
      publishingCode
    ) {
      dispatch(InteractiveVideoActionTypes.CLEAR_ALL);

      const { data } = await axiosInstance.get(
        `/interactivevideo/published/${publishingCode}`
      );
      if (!data.result || !data.data) {
        return;
      }

      commit(InteractiveVideoMutationTypes.SET_INTERACTIVE_VIDEO, data.data);
    },
  },
};
