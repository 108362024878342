import useQuasar from 'quasar/src/composables/use-quasar.js';;

const useDialog = () => {
  const $q = useQuasar();

  const alert = (message: string) => {
    $q.dialog({
      title: "알림",
      message,
    });

    // window.alert(message);
    return;
  };

  const confirm = async (message: string, title: string | undefined) => {
    if (!title) {
      title = "확인";
    }

    return new Promise((resolve) => {
      $q.dialog({
        title,
        message,
        cancel: {
          label: "취소",
        },
        ok: {
          label: "확인",
        },
        persistent: true,
      })
        .onOk(() => {
          resolve(true);
        })
        .onCancel(() => {
          resolve(false);
        })
        .onDismiss(() => {
          resolve(false);
        });
    });
  };

  return {
    alert,
    confirm,
  };
};

export { useDialog };
