export const UserMutationTypes = {
  SET_USERID: "SET_USERID",
  SET_NICKNAME: "SET_NICKNAME",
  SET_PROFILE_IMAGE_URL: "SET_PROFILE_IMAGE_URL",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
};

export const InteractiveVideoMutationTypes = {
  SET_ID: "SET_ID",
  SET_INTERACTIVE_VIDEO_ID: "SET_INTERACTIVE_VIDEO_ID",
  SET_INTERACTIVE_VIDEO: "SET_INTERACTIVE_VIDEO",
  SET_TITLE: "SET_TITLE",
  SET_DESCRIPTION: "SET_DESCRIPTION",
  SET_TAGS: "SET_TAGS",
  SET_TAG_LIST: "SET_TAG_LIST",
  SET_THUMBNAIL_URL: "SET_THUMBNAIL_URL",
  SET_VIDEO_TYPE: "SET_VIDEO_TYPE",
  SET_VIDEO_ID: "SET_VIDEO_ID",
  SET_VIDEO_URL: "SET_VIDEO_URL",
  SET_PUBLISHED_YN: "SET_PUBLISHED_YN",
  SET_PUBLISHING_CODE: "SET_PUBLISHING_CODE",
  SET_BASE64_THUMBNAIL: "SET_BASE64_THUMBNAIL",
};
