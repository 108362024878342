export default {
  decode(token: string | undefined): any {
    if (!token) {
      return null;
    }

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  },

  isExpired(token: string | undefined): boolean {
    if (!token) {
      return true;
    }

    const decoded = this.decode(token);
    const now = new Date().getTime() / 1000;
    return decoded.exp < now;
  },
};
