export const UserActionTypes = {
  CLEAR_ALL_STATE: "CLEAR_ALL_STATE",
  SET_LOGIN_INFO: "SET_LOGIN_INFO",
};

export const InteractiveVideoActionTypes = {
  SET_SOURCE_VIDEO: "SET_SOURCE_VIDEO",
  SET_INTERACTIVE_VIDEO_INFO: "SET_INTERACTIVE_VIDEO_INFO",
  CLEAR_SOURCE_VIDEO: "CLEAR_SOURCE_VIDEO",
  CLEAR_INTERACTIVE_VIDEO: "CLEAR_INTERACTIVE_VIDEO",
  CLEAR_ALL: "CLEAR_ALL",
  GET_DETAIL_BY_ID: "GET_DETAIL_BY_ID",
  GET_DETAIL_BY_PUBLSHING_CODE: "GET_DETAIL_BY_PUBLSHING_CODE",
};
