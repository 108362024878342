import axios from "axios";
import { Module } from "vuex";
import { UserMutationTypes } from "../mutationTypes";
import { UserActionTypes } from "../actionTypes";
import { RootState } from "..";
import { localStorage } from "@/common/storage";

export interface UserState {
  userid: string;
  nickname: string;
  profileImageUrl: string;
  accessToken: string;
  refreshToken: string;
}

// const targetStorage = sessionStorage;
const targetStorage = localStorage;

const REFRESH_TOKEN_STORAGE_ITEM_NAME = "r";
const USERID_STORAGE_ITEM_NAME = "u";
const NICKNAME_STORAGE_ITEM_NAME = "unkn";
const PROFILE_IMG_URL_STORAGE_ITEM_NAME = "prfurl";

// access-token은 메모리에, refresh-token은 localStorage에 저장한다.
export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: () => ({
    userid: targetStorage.getSafeString(USERID_STORAGE_ITEM_NAME),
    nickname: targetStorage.getSafeString(NICKNAME_STORAGE_ITEM_NAME),
    profileImageUrl: targetStorage.getSafeString(
      PROFILE_IMG_URL_STORAGE_ITEM_NAME
    ),
    accessToken: "",
    refreshToken: targetStorage.getSafeString(REFRESH_TOKEN_STORAGE_ITEM_NAME),
  }),

  getters: {
    getNickname(state) {
      return state.nickname;
    },
    getUserid(state) {
      return state.userid;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    isLogin(state) {
      return state.refreshToken != "" && state.refreshToken != null;
    },
  },

  mutations: {
    [UserMutationTypes.SET_USERID](state, userid) {
      state.userid = userid;
      targetStorage.set(USERID_STORAGE_ITEM_NAME, userid);
    },

    [UserMutationTypes.SET_NICKNAME](state, nickname) {
      state.nickname = nickname;
      targetStorage.set(NICKNAME_STORAGE_ITEM_NAME, nickname);
    },

    [UserMutationTypes.SET_PROFILE_IMAGE_URL](state, profileImageUrl: string) {
      state.profileImageUrl = profileImageUrl;
      targetStorage.set(PROFILE_IMG_URL_STORAGE_ITEM_NAME, profileImageUrl);
    },

    [UserMutationTypes.SET_ACCESS_TOKEN](state, accessToken: string) {
      state.accessToken = accessToken;
    },

    [UserMutationTypes.SET_REFRESH_TOKEN](state, refreshToken: string) {
      state.refreshToken = refreshToken;
      targetStorage.set(REFRESH_TOKEN_STORAGE_ITEM_NAME, refreshToken);
    },
  },

  actions: {
    [UserActionTypes.SET_LOGIN_INFO]({ commit }, payload) {
      commit(UserMutationTypes.SET_USERID, payload?.userid);
      commit(UserMutationTypes.SET_NICKNAME, payload?.nickname);
      commit(UserMutationTypes.SET_PROFILE_IMAGE_URL, payload?.profileImageUrl);
    },

    [UserActionTypes.CLEAR_ALL_STATE]({ commit }) {
      commit(UserMutationTypes.SET_USERID, "");
      commit(UserMutationTypes.SET_NICKNAME, "");
      commit(UserMutationTypes.SET_PROFILE_IMAGE_URL, "");
      commit(UserMutationTypes.SET_ACCESS_TOKEN, "");
      commit(UserMutationTypes.SET_REFRESH_TOKEN, "");
    },
  },
};
