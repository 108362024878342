export const localStorage = {
  get(key: string, defaultValue: any): any {
    const value = window.localStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }
    return defaultValue ? defaultValue : null;
  },

  getSafeString(key: string): any {
    return this.get(key, "");
  },

  set(key: string, value: any): void {
    if (!key) {
      return;
    }

    if (!value) {
      value = "";
    }

    const json = JSON.stringify(value);
    window.localStorage.setItem(key, json);
  },
};

export const sessionStorage = {
  get(key: string, defaultValue: any): any {
    const value = window.sessionStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }
    return defaultValue ? defaultValue : null;
  },

  getSafeString(key: string): any {
    return this.get(key, "");
  },

  set(key: string, value: any): void {
    if (!key) {
      return;
    }

    if (!value) {
      value = "";
    }

    const json = JSON.stringify(value);
    window.sessionStorage.setItem(key, json);
  },
};
