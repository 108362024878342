import { createStore, createLogger } from "vuex";
import { userStore as user, UserState } from "@/store/modules/userStore";
import {
  interactiveVideoStore as interactiveVideo,
  InteractiveVideoState,
} from "@/store/modules/interactiveVideoStore";

export interface RootState {
  userState: UserState;
  interactiveVideoState: InteractiveVideoState;
}

export default createStore({
  modules: {
    user,
    interactiveVideo,
  },
  plugins: process.env.NODE_ENV === "development" ? [createLogger()] : [],
});
