import ShareVideoDialog from "@/components/ShareVideoDialog.vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

const useShareVideo = () => {
  const $q = useQuasar();

  const getShareUrl = (publishingCode: string) => {
    const subPath = process.env.VUE_APP_PUBLISHING_URL_SUB_PATH;
    let publishingUrl = process.env.VUE_APP_PUBLISHING_URL;
    if (!publishingUrl) {
      publishingUrl = location.origin;
    }

    return `${publishingUrl}${subPath}/${publishingCode}`;
  };

  const openShareVideoDialog = (video: any) => {
    const url = getShareUrl(video.publishingCode);
    console.log("publishingUrl", url);

    console.log("video", video);

    $q.dialog({
      component: ShareVideoDialog,
      componentProps: {
        shareUrl: url,
        ...video,
      },
    })
      .onOk(() => {
        // console.log('OK')
      })
      .onCancel(() => {
        // console.log('Cancel')
      })
      .onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      });
  };

  return {
    getShareUrl,
    openShareVideoDialog,
  };
};

export { useShareVideo };
